<template>
  <div class="theme-bg fill-height">
    <v-tabs
      v-model="tab"
      class="px-4"
      background-color="theme-bg"
      :grow="false"
    >
      <v-tab v-for="(item, index) in tabComponent" :key="index">
        {{ item.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="theme-bg">
      <v-tab-item v-for="(item, index) in tabComponent" :key="index">
        <component class="theme-bg" :is="item.component" v-if="index === tab" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  data: () => ({
    tab: null,
    tabComponent: [
      {
        name: 'การทำนายความเสียหาย',
        component: () =>
          import('../components/settingpage/ForecastSettings.vue'),
        tab: 0
      },
      {
        name: 'S.R.',
        component: () => import('../components/settingpage/SrSetting.vue'),
        tab: 1
      }
    ]
  })
}
</script>
